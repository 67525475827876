import React from 'react';
import PropTypes from 'prop-types';
import isNewConsumerApp from 'commons/isNewConsumerApp';
import transparentImage from '../../assets/transparent-min.png';

function encodeParams(params) {
  return Object.keys(params)
    .map(key => [key, params[key]].map(encodeURIComponent).join('='))
    .join('&');
}

const ContentfulImage = ({ src, alt, type, className = '' }) => {
  let srcSet;
  let sizes;
  let widths;
  let imageParams;

  switch (type) {
    case 'grid':
      sizes = 'auto';
      imageParams = {
        w: 900,
        fit: 'fill',
        fl: 'progressive',
        h: 338,
      };
      widths = [300, 400, 500, 600, 700, 800, 900, 1000, 1100];
      srcSet = [];
      widths.forEach((width, i) => {
        const srcParams = {
          ...imageParams,
          w: widths[i],
          h: Math.round(widths[i] * (9 / 16)),
        };
        srcSet.push(`${src}?${encodeParams(srcParams)} ${width}w`);
      });
      srcSet = srcSet.join(', ');
      break;

    case 'hero':
      sizes = '100vw';
      imageParams = {
        h: 720,
        w: 1920,
        fit: 'fill',
        fm: 'jpg',
        fl: 'progressive',
      };
      widths = [1366, 1600, 1920, 2000, 2300];
      srcSet = [];
      widths.forEach(width => {
        const srcParams = {
          ...imageParams,
          w: width,
        };
        srcSet.push(`${src}?${encodeParams(srcParams)}`);
      });
      srcSet = srcSet.join(', ');
      break;
    case 'blogProduct':
      sizes = 'auto';
      imageParams = {
        w: 900,
        fit: 'fill',
        fl: 'progressive',
      };
      widths = [300, 400, 500, 600, 700, 800, 900, 1000, 1100];
      srcSet = [];
      widths.forEach((width, i) => {
        const srcParams = {
          ...imageParams,
          w: widths[i],
        };
        srcSet.push(`${src}?${encodeParams(srcParams)} ${width}w`);
      });
      srcSet = srcSet.join(', ');
      break;
    default:
  }
  const publicSrc = `${src}?${encodeParams(imageParams)}`;
  return (
    <img
      src={isNewConsumerApp ? publicSrc : transparentImage}
      alt={alt}
      className={!isNewConsumerApp ? `lazyload ${className}` : className}
      {...(!isNewConsumerApp && {
        'data-sizes': sizes,
        'data-src': publicSrc,
        'data-srcset': srcSet,
      })}
    />
  );
};

ContentfulImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default ContentfulImage;
