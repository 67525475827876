import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/consumer/Link';

const LinkOrFragment = ({ children, className, to }) => {
  // If "to" is defined, render a Link component. Otherwise return the children
  // directly, implicitly wrapped in a Fragment
  if (to) {
    return (
      <Link className={className} to={to}>
        {children}
      </Link>
    );
  }

  return children;
};

LinkOrFragment.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
};

export default LinkOrFragment;
