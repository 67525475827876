import React from 'react';
import PropTypes from 'prop-types';
import JBImage from 'components/essentials/JBImage';
import LinkWithArrow from 'components/essentials/LinkWithArrow/LinkWithArrow';
import CtaLink from 'components/essentials/Cta/CtaLink';
import LinkOrFragment from 'components/consumer/LinkOrFragment';

const ImageTextBlock = ({ imageProps, heading, subText, cta }) => (
  <div>
    {imageProps && imageProps.src && (
      <div className="h-0 overflow-hidden pt-[66%] relative">
        <JBImage
          src={imageProps.src}
          sizes="(max-width: 767px) 100vw, (max-width: 2200px) 50vw, 30vw"
          quality={60}
          className="left-0 h-full object-cover object-[center_center] absolute top-0 w-full"
        />
      </div>
    )}

    <LinkOrFragment to={cta.linkTo ? cta.linkTo : undefined}>
      {heading && heading.content && (
        <h3 className="text-xl font-bold text-gray mt-6 mb-1">
          {heading.content}
        </h3>
      )}

      {subText && subText.content && (
        <div className="text-base text-gray my-4">{subText.content}</div>
      )}
    </LinkOrFragment>

    {cta && cta.linkTo && cta?.style ? (
      <CtaLink to={cta.linkTo} styling={cta.style} color={cta.color}>
        {cta.content}
      </CtaLink>
    ) : (
      <div className="ImageTextBlock__cta">
        <LinkWithArrow to={cta.linkTo}>{cta.content}</LinkWithArrow>
      </div>
    )}
  </div>
);

ImageTextBlock.propTypes = {
  cta: PropTypes.shape({
    linkTo: PropTypes.string,
    content: PropTypes.string,
    style: PropTypes.string,
    color: PropTypes.string,
  }),
  heading: PropTypes.shape({
    content: PropTypes.string,
  }),
  imageProps: PropTypes.shape({
    src: PropTypes.string,
  }),
  subText: PropTypes.shape({
    content: PropTypes.string,
  }),
};

export default ImageTextBlock;
