import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CtaLink from 'components/essentials/Cta/CtaLink';
import JBImage from 'components/essentials/JBImage';
import useScreen from 'hooks/useScreen';
import ContentfulImage from '../ConsumerBlogSlider/components/ContentfulImage/ContentfulImage';

const LargeImageOverlapText = props => {
  const { isMobileOrTablet } = useScreen();

  const {
    imageProps,
    headLine,
    subHeadLine,
    bodyText,
    primaryButton,
    secondaryLink,
    imageOrientation,
    additionalClasses,
  } = props.config;
  let finalOrientation = 'default';
  if (imageOrientation && imageOrientation.value) {
    finalOrientation =
      imageOrientation.value !== 'Image Right' ? 'alt' : 'default';
  }

  return (
    <div
      className={classNames('flex w-full mx-auto my-0', {
        'flex-row-reverse max-[767px]:flex-col': finalOrientation === 'alt',
        'flex-row max-[767px]:flex-col-reverse': finalOrientation === 'default',
      })}
    >
      <div className="max-[767px]:w-full w-[55%] flex flex-row pt-6 pr-12 pb-6 pl-0 transform-[translateX(3.333vw)] bg-white m-0 md:pt-12 md:pr-12 md:pb-12 md:pl-0 md:mx-0 md:my-20 xl:w-[35%] max-[767px]:transform-none">
        <div
          className={classNames(
            'bg-white flex flex-col content-center justify-center font-sans max-[767px]:w-full max-[767px]:text-center',
            {
              'max-[767px]:!pb-6': finalOrientation === 'alt',
            },
            additionalClasses.heading || ''
          )}
        >
          <h1 className="text-3xl font-bold text-gray text-left m-0">
            {headLine.content}
          </h1>

          {subHeadLine && subHeadLine.content && (
            <h2 className="text-xl font-bold mt-6 mx-0 mb-0 text-gray text-left">
              {subHeadLine.content}
            </h2>
          )}

          {bodyText && bodyText.content && (
            <p className="text-base mt-3 mx-0 mb-6 text-gray text-left">
              {bodyText.content}
            </p>
          )}

          <div className="pt-4 flex items-center max-[767px]:p-[5px] max-[767px]:justify-center">
            {!!primaryButton && primaryButton.content && (
              <CtaLink
                to={primaryButton.linkTo}
                color="brand"
                size={isMobileOrTablet ? 'sm' : 'md'}
                styling="solid-button"
              >
                {' '}
                {primaryButton.content}
              </CtaLink>
            )}

            {!!secondaryLink && secondaryLink.content && (
              <CtaLink
                to={secondaryLink.linkTo}
                arrow
                light
                size={isMobileOrTablet ? 'sm' : 'base'}
              >
                {secondaryLink.content}
              </CtaLink>
            )}
          </div>
        </div>
      </div>
      <div className="max-[767px]:w-full w-[70%] [&_img]:w-full [&_img]:align-middle [&_img]:[transition:all_0.25s_ease-in-out] [&_img]:h-screen [&_img]:max-h-[65vh]">
        {imageProps.blogImage ? (
          <ContentfulImage
            src={imageProps.src}
            alt={imageProps.alt}
            lazy={props.lazy}
            className="object-cover"
          />
        ) : (
          <JBImage
            src={imageProps.src}
            alt={imageProps.alt}
            className="half-image"
            crop="focalpoint"
            fpY={0.7}
            sizes="70vw"
            lazy={props.lazy}
          />
        )}
      </div>
    </div>
  );
};

export const LargeImageOverlapTextConfigPropType = PropTypes.shape({
  imageProps: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    blogImage: PropTypes.bool,
  }),
  headLine: PropTypes.shape({
    content: PropTypes.string,
  }),
  subHeadLine: PropTypes.shape({
    content: PropTypes.string,
  }),
  bodyText: PropTypes.shape({
    content: PropTypes.string,
  }),
  primaryButton: PropTypes.shape({
    content: PropTypes.string,
    linkTo: PropTypes.string,
  }),
  secondaryLink: PropTypes.shape({
    content: PropTypes.string,
    linkTo: PropTypes.string,
  }),
  imageOrientation: PropTypes.shape({
    value: PropTypes.string,
  }),
  additionalClasses: PropTypes.shape({
    heading: PropTypes.string,
  }),
});

LargeImageOverlapText.propTypes = {
  lazy: PropTypes.bool,
  config: LargeImageOverlapTextConfigPropType,
};

export default LargeImageOverlapText;
