import React from 'react';
import PropTypes from 'prop-types';
import isNewConsumerApp from 'commons/isNewConsumerApp';

function encodeParams(params) {
  return Object.keys(params)
    .map(key => [key, params[key]].map(encodeURIComponent).join('='))
    .join('&');
}

const ContentfulImage = ({ src, alt, width, height, className = '' }) => {
  let srcSet;
  const sizes = 'auto';
  const imageParams = {
    w: 480,
    h: 319,
    fit: 'fill',
  };
  const widths = [480, 580, 600, 700, 800, 900, 1000, 1100];
  srcSet = [];
  widths.forEach((theWidth, i) => {
    const srcParams = {
      ...imageParams,
      w: widths[i],
      h: widths[i],
    };
    srcSet.push(`${src}?${encodeParams(srcParams)} ${theWidth}w`);
  });
  srcSet = srcSet.join(', ');

  const publicSrc = `${src}?${encodeParams(imageParams)}`;
  return (
    <img
      src={isNewConsumerApp ? publicSrc : undefined}
      className={isNewConsumerApp ? className : `lazyload ${className}`}
      alt={alt}
      {...(!isNewConsumerApp && {
        'data-sizes': sizes,
        'data-src': publicSrc,
        'data-srcset': srcSet,
      })}
      width={width}
      height={height}
    />
  );
};

ContentfulImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ContentfulImage;
